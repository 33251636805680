import React from 'react';
import data from './edital.json';  
import './Edital.css';

const EditalComponent = () => {
  return (
    <div className='edital-container'>
      <h1>{data.titulo}</h1>
      <p className='sub-title'>{data.escritorio}</p>

      <h2>DAS CONSIDERAÇÕES INICIAIS</h2>
      <p>{data.consideracoes_iniciais}</p>

      <h2>DAS VEDAÇÕES</h2>
      <p>{data.vedacoes}</p>

      <h2>DO PROGRAMA GB PRO BONO</h2>
      <p>{data.programa_pro_bono}</p>

      <h2>DA GRATUIDADE DO SERVIÇO</h2>
      <p>{data.gratuidade_servico}</p>

      <h2>DOS CANDIDATOS</h2>
      <p>{data.candidatos}</p>

      <h2>DA SELEÇÃO</h2>
      <p>{data.selecao}</p>

      <h2>DO PRAZO</h2>
      <p>{data.prazo}</p>

      <h2>DO PACTO DE CONFIANÇA</h2>
      <p>{data.pacto_confianca}</p>

      <h2>DA ATUAÇÃO DO ESCRITÓRIO</h2>
      <p>{data.atua_escritorio}</p>

      <h2>CONSIDERAÇÕES FINAIS</h2>
      <p>{data.consideracoes_finais}</p>
    </div>
  );
}

export default EditalComponent;
