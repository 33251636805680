import React, { useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/shared/Header';
import Footer from './components/shared/Footer';
import Home from './components/home/Home';
import Escritorio from './components/home/Escritorio';
import Atuacao from './components/home/Atuacao';
import './App.css';
import './assets/styles/Reset.css';

function App() {
  const main = process.env.REACT_APP_MAIN;


  const edital = useRef();
  const atuacao = useRef();
  const escritorio = useRef();
  const newsletter = useRef();

  const scrollToComponent = (refName) => {
    const refs = {
      edital,
      atuacao,
      escritorio,
      newsletter,
    };

    const selectedRef = refs[refName];

    if (selectedRef && selectedRef.current) {
      var yOffset;
      if (refName === "InicioRef") {
        yOffset = -180;
      } else {
        yOffset = -120;
      }

      const yCoordinate =
        selectedRef.current.getBoundingClientRect().top +
        window.scrollY +
        yOffset;
      window.scrollTo({ top: yCoordinate, behavior: "smooth" });
    }
  };


  return (
    <Router>
      <div className='App'>

        <Header scrollToComponent={scrollToComponent} />

        <div class='body-wrapper'>
          <div class='body-container'>
            <div ref={edital}>
              <Home />
            </div>



            </div> </div>
            <div ref={atuacao}>
              <Atuacao />
            </div>

            <div >
          <div >
            <div ref={escritorio}>
              <Escritorio />
            </div>
          </div>

        </div>
<div ref={newsletter}>
  
          <Footer scrollToComponent={scrollToComponent}  />
</div>
      </div>
    </Router>
  );
}

export default App;

