import React from 'react';
import './Home.css';
import Edital from './Edital.js';
import FormularioEdital from './FormularioEdital.js';

function Home() {
  return (
    <div className='home-container'>
      <Edital />


      
      <FormularioEdital />
    </div>
  );
}

export default Home;

