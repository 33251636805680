import React, { useState, useEffect } from 'react';
import './Alert.css';
import { CSSTransition } from 'react-transition-group';
import DefaultSuccessIcon from '../../assets/imgs/successDefault.svg';
import DefaultWarningIcon from '../../assets/imgs/warningDefault.svg';
import DefaultErrorIcon from '../../assets/imgs/errorDefault.svg';
import DefaultInfoIcon from '../../assets/imgs/infoDefault.svg';


const Notification = ({ isAlertOpen, onClose, flag, title, message, behavior, alertStyle, autoCloseTime }) => {

    const getDefaultStyle = () => {
        let style = {};
        switch (flag) {
            case 'info':
                style = {
                    backgroundColor: 'rgb(7, 19, 24)',
                    color: 'rgb(41, 182, 246)',
                };
                break;
            case 'success':
                style = {
                    backgroundColor: 'rgb(12, 19, 13)',
                    color: 'rgb(204, 232, 205)',
                };
                break;
            case 'warning':
                style = {
                    backgroundColor: 'rgb(25, 18, 7)',
                    color: 'rgb(255, 226, 183)',
                };
                break;
            case 'alert':
                style = {
                    backgroundColor: 'rgb(22, 11, 11)',
                    color: 'rgb(244, 199, 199)',
                };
                break;
            default:
                break;
        }

        return style;
    };

    const getFilledStyle = () => {
        let style = {};

        switch (flag) {
            case 'alert':
                style = {
                    backgroundColor: getBackgroundFiledColor(),
                    color: 'white',
                };
                break;
            default:
                style = {
                    backgroundColor: getBackgroundFiledColor(),
                    color: 'black',
                };
                break;
        }

        return style;
    };

    const getBackgroundFiledColor = () => {
        let color = '';
        switch (flag) {
            case 'info':
                color = 'rgb(41, 182, 246)';
                break;
            case 'success':
                color = 'rgb(102, 187, 106)';
                break;
            case 'warning':
                color = 'rgb(255, 167, 38)';
                break;
            case 'alert':
                color = 'rgb(244, 67, 54)';
                break;
            default:
                break;
        }

        return color;
    };
/* 
    const getFilledIcon = () => {
        let icon = '';

        switch (flag) {
            case 'info':
                icon = InfoIcon;
                break;
            case 'success':
                icon = SuccessIcon;
                break;
            case 'warning':
                icon = WarningIcon;
                break;
            case 'alert':
                icon = ErrorIcon;
                break;
            default:
                break;
        }

        return (
            <><img src={icon} alt='ícone de sucesso'></img></>
        );
    }; */

    const getIcon = () => {
/* 
        if(alertStyle === 'filed'){
            return getFilledIcon();
        } */

        let icon = '';

        switch (flag) {
            case 'info':
                icon = DefaultInfoIcon;
                break;
            case 'success':
                icon = DefaultSuccessIcon;
                break;
            case 'warning':
                icon = DefaultWarningIcon;
                break;
            case 'alert':
                icon = DefaultErrorIcon;
                break;
            default:
                break;
        }

        return (
            <><img src={icon} alt='ícone de sucesso'></img></>
        );
    };

    const getButtonColor = () => {
        let color = '';

        if(flag === 'alert' && alertStyle === 'filed'){
            return 'white';
        } else if (alertStyle === 'filed') {
            return 'black';
        }

        switch (flag) {
            case 'info':
                color = 'rgb(41, 182, 246)';
                break;
            case 'success':
                color = 'rgb(204, 232, 205)';
                break;
            case 'warning':
                color = 'rgb(255, 226, 183)';
                break;
            case 'alert':
                color = 'rgb(244, 199, 199)';
                break;
            default:
                break;
        }

        return color;
    };

    useEffect(() => {
        let timeoutId;

        if (autoCloseTime && isAlertOpen) {
          timeoutId = setTimeout(() => {
            onClose();
          }, autoCloseTime);
        }
    
        return () => {
          clearTimeout(timeoutId);
        };
      }, [autoCloseTime, isAlertOpen, onClose]);

    return (
        <CSSTransition in={isAlertOpen} timeout={500} classNames='float' unmountOnExit mountOnEnter>
        <div
            className={`notification ${behavior === 'float' ? 'float' : 'block'}`}
            style={alertStyle === 'filed' ? getFilledStyle() : getDefaultStyle()}
        >
            <div className="notification-content">
                <div className="icon-container">{getIcon()}</div>
                <div className="notification-text">
                    <h2>{title}</h2>
                    <p>{message}</p>
                </div>
                <button
                    style={{ color: getButtonColor() }}
                    className="close-button"
                    onClick={onClose}
                >
                    ×
                </button>

            </div>
        </div>
        </CSSTransition >
    );
};

export default Notification;
