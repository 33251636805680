import React, { useState } from "react";
import { Link } from 'react-router-dom';
import "./Atuacao.css";
import Icon1 from '../../assets/imgs/icon1.png';
import Icon2 from '../../assets/imgs/icon2.png';
import Icon3 from '../../assets/imgs/icone-direito-penal.png';
import Icon4 from '../../assets/imgs/investig-def-icon.png';

function Atuacao() {
  const [activeButton, setActiveButton] = useState(null);

  const ativarClasseNoBotao = (index) => {
    setActiveButton(index);
  };

  const desativarClasseNoBotao = () => {
    setActiveButton(null);
  };

  return (
    <div className="home-atuacao-container">
      <h1 className="section-title">
        Veja as áreas de atuação e especialidades
      </h1>
      <p className="section-subtitle">
        O modo de trabalho adotado é determinado pela busca incessante da
        excelência dentro do Direito, regada ao contínuo e intenso estudo das
        ciências criminais e empresariais.
      </p>

      <div className="atuacao-flex">
        <div className="atuacao-flex-row">
          <a href="https://gabrielbulhoes.com.br/portfolio/direito-penal-economico/">
            <div
              onMouseOver={() => ativarClasseNoBotao(1)}
              onMouseOut={desativarClasseNoBotao}
            >
              <img src={Icon1} alt="balança"/>
              <h4>Direito Penal Econômico</h4>
              <button className={`atuacao-btn  main-btn ${activeButton === 1 ? 'ativo' : ''}`}>
                SAIBA MAIS
              </button>
            </div>
          </a>

          <a href="https://gabrielbulhoes.com.br/portfolio/defesa-penal-classica/">
            <div
              onMouseOver={() => ativarClasseNoBotao(2)}
              onMouseOut={desativarClasseNoBotao}
            >
              <img src={Icon3} alt="livro de leis" />
              <h4>Defesa Penal Clássica</h4>
              <button className={`atuacao-btn  main-btn ${activeButton === 2 ? 'ativo' : ''}`}>
                SAIBA MAIS
              </button>
            </div>
          </a>
        </div>


        <div className="atuacao-flex-row">
          <a href="https://gabrielbulhoes.com.br/portfolio/corporate-criminal-compliance/">
            <div
              onMouseOver={() => ativarClasseNoBotao(3)}
              onMouseOut={desativarClasseNoBotao}
            >
              <img src={Icon2} alt="aperto de mãos" />
              <h4>Compliance Criminal & Investigações Internas Corporativas</h4>
              <button className={`atuacao-btn  main-btn ${activeButton === 3 ? 'ativo' : ''}`}>
                SAIBA MAIS
              </button>
            </div>
          </a>
          <a href="https://gabrielbulhoes.com.br/portfolio/investigacao-defensiva/">
            <div
              onMouseOver={() => ativarClasseNoBotao(4)}
              onMouseOut={desativarClasseNoBotao}
            >
              <img src={Icon4} alt="livros" />
              <h4>Investigação Defensiva</h4>
              <button className={`atuacao-btn main-btn ${activeButton === 4 ? 'ativo' : ''}`}>
                SAIBA MAIS
              </button>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Atuacao;
