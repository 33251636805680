import React, { useState } from "react";
import "./Edital.css";
import Alert from "../utils/Alert.js";

const FormularioComponent = () => {
const [error, setError] = useState(false);
const [isAlertOpen, setIsAlertOpen] = useState(false);

  const [declaracao, setDeclaracao] = useState(false);
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [profissao, setProfissao] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [endereco, setEndereco] = useState("");
  const [ligacao, setLigacao] = useState("");
  const [numeroProcesso, setNumeroProcesso] = useState("");
  const [rendaMensal, setRendaMensal] = useState("");
  const [advocaciaPrivada, setAdvocaciaPrivada] = useState("");
  const [descricao, setDescricao] = useState("");

  const clearForm = () => {
    setNomeCompleto("");
    setProfissao("");
    setEmail("");
    setTelefone("");
    setEndereco("");
    setLigacao("");
    setNumeroProcesso("");
    setRendaMensal("");
    setAdvocaciaPrivada("");
    setDescricao("");
    setDeclaracao(false);
  };

  const toggleAlert = () => {
    setIsAlertOpen(!isAlertOpen);
  };

  const handleAdvocaciaPrivadaChange = (e) => {
    setAdvocaciaPrivada(e.target.value);
  };

  const handleRendaChange = (e) => {
    setRendaMensal(e.target.value);
  };

  const handleDeclaracaoChange = () => {
    setDeclaracao(!declaracao);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = {
      declaracao,
      nomeCompleto,
      profissao,
      email,
      telefone,
      endereco,
      ligacao,
      numeroProcesso,
      rendaMensal,
      advocaciaPrivada,
      descricao,
    };
  
    try {
      const response = await fetch('https://jacquesgomes.com.br/gb/form-pro-bono', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        setError('Erro ao enviar o formulário.');
        throw new Error('Erro ao enviar o formulário.');
      }
  
      console.log('Formulário enviado com sucesso!');
      toggleAlert();
      setError(null);
      clearForm();
    } catch (error) {
      console.error('Erro ao enviar o formulário:', error.message);
      setError(error.message);
    }
  };
  

  return (
    <div className="formulario-container">
      <h1>Formulário de Inscrição</h1>

      <form onSubmit={handleSubmit}>
        <div className="regular-label-container">
          <label>
            Nome Completo*
            <input
              type="text"
              value={nomeCompleto}
              onChange={(e) => setNomeCompleto(e.target.value)}
              maxLength={100}
              required
              
            />
          </label>

          <label>
            Profissão*
            <input

    required
              type="text"
              value={profissao}
              onChange={(e) => setProfissao(e.target.value)}
              maxLength={50}
            />
          </label>

          <label>
            Email*
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              maxLength={50}

    required
            />
          </label>

          <label>
            Telefone*
            <input
              type="text"
              value={telefone}
              onChange={(e) => setTelefone(e.target.value)}
              maxLength={50}

    required
            />
          </label>

          <label>
            Endereço Completo*
            <input
              type="text"
              value={endereco}
              onChange={(e) => setEndereco(e.target.value)}
              maxLength={160}

    required
            />
          </label>

          <label>
            Qual a sua ligação com a pessoa acusada (amigo, parente, etc.)*
            <input
              type="text"
              value={ligacao}
              onChange={(e) => setLigacao(e.target.value)}
              maxLength={350}

    required
            />
          </label>

          <label>
            Número do Processo/Recurso/HC/Outro
            <input
              type="text"
              value={numeroProcesso}
              onChange={(e) => setNumeroProcesso(e.target.value)}

              maxLength={450}
            />
          </label>

          <label>
            Descreva o caso*
            <textarea
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}

              required
              maxLength={20000}
            />
          </label>
        </div>

        <div className="non-regular-label-container">
          <div>
            <label>
              Selecione a faixa de renda familiar mensal da pessoa acusada*
            </label>

            <div>
              <input
                type="radio"
                id="ate1000"
                value="até R$ 1.000,00"
                checked={rendaMensal === "até R$ 1.000,00"}
                onChange={handleRendaChange}
              />
              <label htmlFor="ate1000">Até R$ 1.000,00</label>
            </div>

            <div>
              <input
                type="radio"
                id="1001a3000"
                value="R$ 1.001,00 a R$ 3.000,00"
                checked={rendaMensal === "R$ 1.001,00 a R$ 3.000,00"}
                onChange={handleRendaChange}
              />
              <label htmlFor="1001a3000">R$ 1.001,00 a R$ 3.000,00</label>
            </div>

            <div>
              <input
                type="radio"
                id="3001a10000"
                value="R$ 3.001,00 a R$ 10.000,00"
                checked={rendaMensal === "R$ 3.001,00 a R$ 10.000,00"}
                onChange={handleRendaChange}
              />
              <label htmlFor="3001a10000">R$ 3.001,00 a R$ 10.000,00</label>
            </div>

            <div>
              <input
                type="radio"
                id="MaisDe10000"
                value="Mais de R$ 10.000,00"
                checked={rendaMensal === "Mais de R$ 10.000,00"}
                onChange={handleRendaChange}
              />
              <label htmlFor="MaisDe10000">Mais de R$ 10.000,00</label>
            </div>
          </div>

          <div>
            <label>Existe profissional da advocacia (privada) atuando?*</label>

            <div>
              <input
                type="radio"
                id="sim"
                value="Sim"
                checked={advocaciaPrivada === "Sim"}
                onChange={handleAdvocaciaPrivadaChange}
              />
              <label htmlFor="sim">Sim</label>
            </div>

            <div>
              <input
                type="radio"
                id="nao"
                value="Não"
                checked={advocaciaPrivada === "Não"}
                onChange={handleAdvocaciaPrivadaChange}
              />
              <label htmlFor="nao">Não</label>
            </div>

            <div>
              <input
                type="radio"
                id="defensoriaPublica"
                value="Defensoria Pública"
                checked={advocaciaPrivada === "Defensoria Pública"}
                onChange={handleAdvocaciaPrivadaChange}
              />
              <label htmlFor="defensoriaPublica">Defensoria Pública</label>
            </div>
          </div>
        </div>
        <div className="checkbox-container">
          <input
            type="checkbox"
            checked={declaracao}
            required
            onChange={handleDeclaracaoChange}
          />
          <span>
            Declaro que li o Edital acima, concordo com seus termos e, por isso,
            desejo continuar.
          </span>
        </div>

      <p style={{fontSize: "14px"}}>Os campos com o símbolo '*' são de preenchimento obrigatório.</p>

      {error && <p style={{color: "red", fontSize: '14px'}}>{error}</p>}

        <button className="main-btn" type="submit">
          Enviar Formulário
        </button>

      </form>

      <Alert
        isAlertOpen={isAlertOpen}
        onClose={toggleAlert}
        flag="success"
        title="Sucesso!"
        message="Formulário enviado com sucesso! Obrigado!"
        behavior="block"
      />

    </div>
  );
};

export default FormularioComponent;
