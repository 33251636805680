import "./Footer.css";
import React, { useMemo } from "react";
import Logo from "../../assets/imgs/logo-branca-metade.png";
import menuItems from './Btns-Content';
import MenuBtn from './MenuBtn';
import Demo from '../newsletter/Demo';

const Footer = ({ scrollToComponent }) => {

  const action = (address) => {
    if (address !== "Link") {
      scrollToComponent(address);
    } else {
      scrollUp();
    }
  };

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const memoizedMenuItems = useMemo(() => {
    return menuItems.map((menuItem, index) => (

        <MenuBtn className="footer-menu-btn" action={action} data={menuItem} scrollToComponent={scrollToComponent} />

    ));
  }, [menuItems]);

  return (
    <footer className="footer-background">
      <div className="footer-container header-body-footer-width">
        <div class="footer-container-division">
          
          <div className="footer-wrap">
            <div className="footer-logo">
              <img className="footer-img" src={Logo} alt="logo"></img>
            </div>
            <div></div>
            <div>
              <Demo />
            </div>
          </div>
          <div className="footer-wrap footer-content">
            <div className="footer-endereco">
              <h5>ENCONTRE-NOS</h5>
              <p>
                <span>Endereço</span>
                OTC - Office Tower Center, Sala 303
                <br></br>
                Rua Promotor Manoel Alves Pessoa Neto, 45
                <br></br>
                Candelária, Natal, CEP: 59065-555
                <br></br>
                <br></br>
                <br></br>
                CNPJ n.º 29.631.903/0001-52
                <br></br>
                <br></br>
                Sociedade de Advogados n.º 798 (OAB/RN)
              </p>
            </div>
            <div className="footer-menu">
              <h5>NAVEGAÇÃO</h5>
              <div>
                {memoizedMenuItems}
              </div>
            </div>
            <div className="footer-menu">
              <h5>ÁREAS E ESPECIALIDADES</h5>
              <div>
                <a
                  href="https://gabrielbulhoes.com.br/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="site principal do escritório"
                >
                  Investigação Defensiva
                </a>
                <a
                  href="https://gabrielbulhoes.com.br/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="site principal do escritório"
                >
                  Compliance Criminal & Investigações Internas Corporativas
                </a>
                <a
                  href="https://gabrielbulhoes.com.br/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="site principal do escritório"
                >
                  Direito Penal Econômico
                </a>
                <a
                  href="https://gabrielbulhoes.com.br/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="site principal do escritório"
                >
                  Defesa Penal Clássica
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-wrap footer-bottom-container">
          <div className="footer-copy">
            <p>&copy; 2023 Gabriel Bulhões.</p>
          </div>
          <div className="footer-socials">
            <a
              href="https://www.linkedin.com/company/gb-advocacia-criminal/"
              target="_blank"
              rel="noreferrer"
              aria-label="site principal do escritório"
            >
              <i className="uil uil-linkedin"></i>
            </a>

            <a
              href="https://www.instagram.com/gbadvocaciacriminal/"
              target="_blank"
              aria-label="site principal do escritório"
              rel="noreferrer"
            >
              <i className="uil uil-instagram"></i>
            </a>

            <a
              href="            https://wa.me/message/IMG5YFUHUYM5D1"
              aria-label="Whatsapp Icon"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-whatsapp"></i>
            </a>

            <a
              href="https://www.facebook.com/gbadvocaciacriminal"
              target="_blank"
              rel="noreferrer"
              aria-label="site principal do escritório"
            >
              <i className="uil uil-facebook-f"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
